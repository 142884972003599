import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
/** RXJS */
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
/** SERVICES */
import { BaseService } from '@services/base.service';
/** MODELS */
import { Profile } from '@models/profile.data-list';

@Injectable()
export class ProfileResolver {
  private endpoint = '/admin/profile/';

  constructor(
    private readonly service: BaseService,
    private router: Router,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Profile | null> {
    return this.service.get(this.endpoint + route.params.profileId).pipe(
      catchError(() => {
        this.router.navigate(['/unauthorized']);
        return of(null);
      }),
    );
  }
}
